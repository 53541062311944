/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';

import {store} from "../../../store/store";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

var d = new Date();

const SideBar = () => {
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "",
    ],
    entry = [
        "add-entry",
        "all-entries",
    ],
    clients = [
        "add-client",
        "all-clients",
    ],

    transactions = [
        "all-transactions",
    ],
    bills = [
        "bills",
    ],
    rateCharts = [
        "fat-snf-chart",
    ],
    settings = [
        "settings",
    ],
    plans = [
        "plans",
    ],
    faqs = [
        "faqs",
    ]
  
    const userDetails = store.getState().auth.user;
    
  return (
    <div
      className={`dlabnav ${iconHover} fixed`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
		  	<Dropdown className="dropdown header-profile2">
			  <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<div className="header-info2 d-flex align-items-center border">
				  <img src={userDetails.picture} width={20} alt="profile-pic" className="rounded-circle" />
				  <div className="d-flex align-items-center sidebar-info">
					<div>
					  <span className="font-w700 d-block mb-2">{userDetails.name}</span>
					  <small className="text-end font-w400">{userDetails.email.substring(0, 16) }{userDetails.email.length > 17 ? '...' : ''}</small>
					</div>
					<i className="fas fa-sort-down ms-4"></i>
				  </div>
				</div>
			  </Dropdown.Toggle>
				  <Dropdown.Menu align="right" className=" dropdown-menu dropdown-menu-end">
					<Link to="/app-profile" className="dropdown-item ai-icon">
					  <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
						width={18} height={18} viewBox="0 0 24 24" fill="none"
						stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
					  >
						<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
						<circle cx={12} cy={7} r={4} />
					  </svg>
					  <span className="ms-2">Profile</span>
					</Link>
					<LogoutPage />
				  </Dropdown.Menu>
			  </Dropdown> 
        <MM className="metismenu" id="menu">
            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                <Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard" >
                <i className="fas fa-home"></i>
                <span className="nav-text">Dahsboard</span>
                </Link>
            </li>

            <li className={`${entry.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/#" >
                    <i className="fas fa-list"></i>
                    <span className="nav-text">Entry</span>
                </Link>
                <ul >
                    <li><Link className={`${path === "add-entry" ? "mm-active" : ""}`} to="/add-entry"> Add Entry</Link></li>
                    <li><Link className={`${path === "all-entries" ? "mm-active" : ""}`} to="/all-entries"> All Entries</Link></li>
                </ul>
            </li>

            <li className={`${clients.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/#" >
                    <i className="fas fa-users"></i>
                    <span className="nav-text">Clients</span>
                </Link>
                <ul >
                    <li><Link className={`${path === "add-client" ? "mm-active" : ""}`} to="/add-client"> Add Client</Link></li>
                    <li><Link className={`${path === "all-clients" ? "mm-active" : ""}`} to="/all-clients"> All Clients</Link></li>
                </ul>
            </li>

            <li className={`${transactions.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/#" >
                    <i className="fas fa-exchange-alt"></i>
                    <span className="nav-text">Transactions</span>
                </Link>
                <ul >
                    <li><Link className={`${path === "all-transactions" ? "mm-active" : ""}`} to="/all-transactions">All Transactions</Link></li>
                </ul>
            </li>

            <li className={`${bills.includes(path) ? "mm-active" : ""}`}>
                <Link className={`${path === "bills" ? "mm-active" : ""}`} to="/bills" >
                <i className="fas fa-book"></i>
                <span className="nav-text">Bills</span>
                </Link>
            </li>

            <li className={`${rateCharts.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow" to="/#" >
                    <i className="fas fa-file-alt"></i>
                    <span className="nav-text">Charts</span>
                </Link>
                <ul >
                    <li><Link className={`${path === "fat-snf-chart" ? "mm-active" : ""}`} to="/fat-snf-chart">Fat &amp; SNF</Link></li>
                </ul>
            </li>

            <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
                <Link className={`${path === "settings" ? "mm-active" : ""}`} to="/settings" >
                <i className="fas fa-cogs"></i>
                <span className="nav-text">Settings</span>
                </Link>
            </li>

            <li className={`${plans.includes(path) ? "mm-active" : ""}`}>
                <Link className={`${path === "plans" ? "mm-active" : ""}`} to="/plans" >
                <i className="fas fa-key"></i>
                <span className="nav-text">Plans</span>
                </Link>
            </li>

            <li className={`${faqs.includes(path) ? "mm-active" : ""}`}>
                <Link className={`${path === "faqs" ? "mm-active" : ""}`} to="/faqs" >
                <i className="fas fa-question"></i>
                <span className="nav-text">FAQs</span>
                </Link>
            </li>



        </MM>
		<div className="copyright">
			<p>© 2018-{d.getFullYear()} All Rights Reserved</p>
			<p className="fs-12">Made with <span className="heart"></span> by DJS DAIRY</p>
		</div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
