import axios from 'axios';
import {config} from '../config';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(name, email, password) {
    //axios call

    const postData = {
        email,
        password,
        name,
        redirect_link: `${config.frontUrl}`
    };
    return axios.post(
        `${config.baseUrl}/auth-register`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password
    };
    return axios.post(
        `${config.baseUrl}/auth-login`,
        postData,
    );
}

export function formatError(errorResponse) {
    swal("Oops", errorResponse.message, "error");
}

export function saveTokenInLocalStorage(tokenDetails) {
    try {
        tokenDetails.expireDate = new Date(
            new Date().getTime() + ( tokenDetails.expiresIn * 1000), // change as per token expire
        );
        localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    } catch (error) {
        console.log("error", error);
    }
    
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
