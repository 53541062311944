import React, { Fragment, } from "react";
// import { useStore } from "react-redux";
import {store} from "../../../../store/store";
import PageTitle from "../../../layouts/PageTitle";


// import { Button, Dropdown, Modal, Tab, Nav, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { SRLWrapper } from "simple-react-lightbox";
// //** Import Image */
// import profile01 from "../../../../images/profile/1.jpg";
// import profile02 from "../../../../images/profile/2.jpg";
// import profile03 from "../../../../images/profile/3.jpg";
// import profile04 from "../../../../images/profile/4.jpg";
// import profile05 from "../../../../images/profile/5.jpg";
// import profile06 from "../../../../images/profile/6.jpg";
// import profile07 from "../../../../images/profile/7.jpg";
// import profile08 from "../../../../images/profile/8.jpg";
// import profile09 from "../../../../images/profile/9.jpg";

// const initialState = false;
// const reducer = (state, action) => {
//   switch (action.type) {
//     case "sendMessage":
//       return { ...state, sendMessage: !state.sendMessage };
//     case "postModal":
//       return { ...state, post: !state.post };
//     case "linkModal":
//       return { ...state, link: !state.link };
//     case "cameraModal":
//       return { ...state, camera: !state.camera };
//     case "replyModal":
//       return { ...state, reply: !state.reply };
//     default:
//       return state;
//   }
// };

const AppProfile = () => {
//   const [state, dispatch] = useReducer(reducer, initialState);

//   const options = {
//     settings: {
//       overlayColor: "#000000",
//     },
//   };

const userDetails = store.getState().auth.user;

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={userDetails.picture}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{userDetails.name}</h4>
                    <p>UX / UI Designer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{userDetails.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-statistics">
                <div className="text-center">
                  <div className="row">
                    <div className="col">
                      <h3 className="m-b-0">150</h3>
                      <span>Follower</span>
                    </div>
                    <div className="col">
                      <h3 className="m-b-0">140</h3> <span>Place Stay</span>
                    </div>
                    <div className="col">
                      <h3 className="m-b-0">45</h3> <span>Reviews</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
